<!-- 运营端和代理商端通用 代理商业绩统计 -->
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">线上考试统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
              <span title="学员信息" class="itemLabel">学员信息:</span>
              <el-select v-model="retrievalData.userId" placeholder="请选择学员" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style=" color: #8492a6; float: left; font-size: 13px; width: 100px;">学员姓名</span>
                    <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 140px;">身份证号码</span>
                    <span style=" color: #8492a6; float: left; font-size: 13px; margin-left: 50px; width: 100px;">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="userDatas.userName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="userDatas.idcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="userDatas.mobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userDatas.userList" :key="index" :label="item.userName"
                  :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{ item.userName }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="考场名称" class="searchboxItem ci-full">
              <span class="itemLabel">考场名称:</span>
              <el-input
                v-model.trim="retrievalData.roomName"
                clearable
                placeholder="请输入考场名称"
                size="small"
              ></el-input>
            </div>
            <div title="座位号" class="searchboxItem ci-full">
              <span class="itemLabel">座位号:</span>
              <el-input
                v-model.trim="retrievalData.seatNumber"
                clearable
                placeholder="请输入座位号"
                size="small"
              ></el-input>
            </div>
            <div title="准考证号" class="searchboxItem ci-full">
              <span class="itemLabel">准考证号:</span>
              <el-input
                v-model.trim="retrievalData.userCandidateNo"
                clearable
                placeholder="请输入座位号"
                size="small"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="statistics">
          <span>考试名称: {{ dataStatistics.examinationName }}</span>
          <span>考试科目: {{ dataStatistics.subjectCode }}</span>
          <span>培训工种: {{ dataStatistics.occupationName }}</span>
          <span>培训等级: {{ dataStatistics.levelCode }}</span>
          <span>试卷分数: {{ dataStatistics.totalScore }}分</span>
          <span>考试时间: {{ dataStatistics.startTime }} ~ {{ dataStatistics.endTime }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="100"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="180"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                width="160"
              />
              <el-table-column
                label="考场"
                align="left"
                show-overflow-tooltip
                prop="roomName"
                width="100"
              />
              <el-table-column
                label="座位号"
                align="left"
                show-overflow-tooltip
                prop="seatNumber"
                width="80"
              />
              <el-table-column
                label="准考证号"
                align="left"
                show-overflow-tooltip
                prop="userCandidateNo"
                width="160"
              />
              <el-table-column
                label="考试时间"
                align="center"
                show-overflow-tooltip
                prop="projectName"
                width="260"
                ><template slot-scope="scope">
                  {{ scope.row.startTime }} ~ {{ scope.row.endTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="考试次数"
                align="left"
                show-overflow-tooltip
                prop="examNum"
                width="100"
              />
              <el-table-column
                label="强制收卷"
                align="left"
                show-overflow-tooltip
                prop="forcedWinding"
                width="100"
                ><template slot-scope="scope">
                  {{ scope.row.forcedWinding? '是':'否' }}
                </template>
              </el-table-column>
              <el-table-column
                label="标记内容"
                align="left"
                show-overflow-tooltip
                prop="markContent"
                min-width="200"
                ><template slot-scope="scope">
                  {{ scope.row.markContent? scope.row.markContent:'--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="交卷状态"
                align="left"
                show-overflow-tooltip
                prop="submitString"
                width="100"
              />
              <el-table-column
                label="阅卷状态"
                align="left"
                show-overflow-tooltip
                prop="scoringState"
                width="100"
                ><template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_SCORING_STATE", scope.row.scoringState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="客观题分数"
                align="left"
                show-overflow-tooltip
                prop="objectiveScore"
                width="100"
                ><template slot-scope="scope">
                  {{ scope.row.objectiveScore? scope.row.objectiveScore:'--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="主观题分数"
                align="left"
                show-overflow-tooltip
                prop="subjectiveScore"
                width="100"
                ><template slot-scope="scope">
                  {{ scope.row.subjectiveScore? scope.row.subjectiveScore:'--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="认证状态"
                align="left"
                show-overflow-tooltip
                prop="authenticationState"
                width="120"
                ><template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_AUTHENTICATION_STATE", scope.row.authenticationState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="学员照片"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="180"
              ><template slot-scope="scope">
                <el-image
                  v-if="scope.row.authenticationPhoto"
                  style="width: 40px; height: 50px;"
                  :src="scope.row.authenticationPhoto"
                  :preview-src-list="[scope.row.authenticationPhoto]"
                  ></el-image>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="190px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click="resetPassword (scope.row)"
                    >重置密码</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    :disabled="!scope.row.examinationPerformanceId"
                    @click="answerDetails(scope.row)"
                    >答题详情</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "workOrder_onlineExamStatistics_seeStudent",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  //页面初始化时不自动查询列表数据
  // doNotInit: true,
  data() {
    return {
      // 上个页面传参
      lastPage: {
        examinationId: "", // 考试id
      },
      // 查询条件
      retrievalData: {
        userId: "", // 学员id
        roomName: "", // 考场名称
        seatNumber: "", // 座位号
        userCandidateNo: "", // 准考证号
      },
      // 学员检索相关数据
      userDatas: {
        userName: "",//学员姓名
        idcard: "",//身份证号
        mobile: "",//手机号
        userList: [], // 学员下拉数据
      },
      // 数据统计
      dataStatistics:{},
    };
  },
  watch: {},
  created() {
    this.lastPage.examinationId= this.$route.query.examinationId; // 考试id
    this.getDataStatistics();
  },
  computed: {},
  mounted() {},
  methods: {
    // 获取 - 学员信息 - 下拉数据
    superUserSelect() {
      this.$post(
        "/run/examination/public/user/page",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.userDatas.userName,
          idcard: this.userDatas.idcard,
          mobile: this.userDatas.mobile,
        },
        3000,
        false,
        6
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userDatas.userList = [{}];
        } else {
          this.userDatas.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userDatas.userName = "";
        this.userDatas.idcard = "";
        this.userDatas.mobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 数据统计
    getDataStatistics(){
      this.$post(
        "/run/examination/getInfo",
        {
          examinationId: this.$route.query.examinationId,
        },
        3000,
        false,
        6
      ).then((res) => {
        this.dataStatistics = res.data;
      });
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examinationId: this.$route.query.examinationId,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/run/examination/user/page",
        params,
        pageNum,
      },true,6);
    },
    // 重置密码
    resetPassword(row){
      this.$confirm('是否将学员账号的密码重置？重置后，变为初始密码Aa+身份证号后六位', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'bgc-bv',
        cancelButtonText: '取消',
      }).then(() => {
        this.$post("/biz/examination/user/resetPassword", { userId: row.userId }, 3000, true, 6)
          .then((res) => {
            this.getData(this.pageNum)
            this.$message({
              message: "重置成功",
              type: "success",
            });
          })
          .catch((err) => {
            return
          });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
    },
    // 答题详情
    answerDetails(row) {
      this.$router.push({
        path: "/web/examination/reviewPaperDetail",
        query: {
          examinationId: row.examinationId,
          examinationPerformanceId: row.examinationPerformanceId,
          markType: '01'
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.statistics {
  margin: 5px 15px 0;
  span + span {
    margin-left: 15px;
  }
}
</style>
